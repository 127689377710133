/**
 * @desc 基础数据 教师
 */
import { Table, TableColumn, Input, Button, Checkbox } from 'element-ui';
import basicHeader from '@/components/backStageHeader';
import searchInput from '@/components/searchInput';
import qlPagination from '@/components/qlPagination';
import teacherDialog from './components/teacherDialog';
import passwordDialog from './components/passwordDialog';
// api
import { getTeacherListNew, getTeacherList, deleteTeacher, batchDeleteTeacher } from '@/api/basic';

// const
import * as consts from './constance';

export default {
  name: 'basic-teacher',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    basicHeader,
    searchInput,
    qlPagination,
    teacherDialog,
    passwordDialog,
  },
  data() {
    return {
      grades: [
        {
          gradeId: '',
          gradeName: '全部',
        },
        {
          gradeId: '-1',
          gradeName: '行政人员',
        },
      ],
      table: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
        gradeId: '',
        userName: '',
      },
      multipleSelection: [],
      selectAll: false,
      teacherDialogData: {
        visible: false,
        optionType: consts.DIALOG_TYPE_ADD,
        teacherId: '',
      },
      passwordDialogData: {
        visible: false,
        optionType: consts.PASSWORD_RESET_ONE,
        teachers: [],
      },
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    notAllowOperate() {
      return this.userInfo.thirdpartySchoolProjectCode !== '';
    },
  },
  methods: {
    // handle 切换tag
    handleTag(val) {
      this.table.gradeId = val.gradeId;
      this.table.userName = '';
      this.table.pageIndex = 1;
      this.selectAll = false;
      this.multipleSelection = [];
      this.$refs.table.clearSelection();
      this.getTeacherPageJSON();
    },
    // handle 搜索
    handleSearch() {
      this.table.pageIndex = 1;
      this.getTeacherPageJSON();
    },
    // handle 新增
    handleAdd() {
      if (this.notAllowOperate) return;
      this.teacherDialogData.optionType = consts.DIALOG_TYPE_ADD;
      this.teacherDialogData.visible = true;
    },
    // handle 编辑
    handleEdit(val) {
      if (this.notAllowOperate) return;
      this.teacherDialogData.optionType = consts.DIALOG_TYPE_EDIT;
      this.teacherDialogData.teacherId = val.userId;
      this.teacherDialogData.visible = true;
    },
    // handle 修改密码
    handleChangePassword(val) {
      if (this.notAllowOperate) return;
      this.passwordDialogData.optionType = consts.PASSWORD_RESET_ONE;
      this.passwordDialogData.teachers = [val];
      this.passwordDialogData.visible = true;
    },
    // handle 修改密码成功
    handlePasswordSuccess() {
      this.passwordDialogData.visible = false;
      this.selectAll = false;
      this.multipleSelection = [];
      this.$refs.table.clearSelection();
    },
    // handle 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (val.length) {
        this.selectAll = false;
      }
    },
    // handle 全部
    handleSelectAll(val) {
      val && this.$refs.table.clearSelection();
    },
    // handle 批量删除
    handleBatchDelete() {
      if (this.notAllowOperate) return;

      this.$msgbox
        .confirm(`你确定禁用${this.multipleSelection.map((x) => x.userName).join(',')}吗?`, '提示', {
          customClass: 'basic-data-teacher-batch-delete-dialog',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          batchDeleteTeacher({
            teacherIds: this.multipleSelection.map((x) => x.userId).join(','),
          }).then(() => {
            this.$message({
              type: 'success',
              message: `已禁用${this.multipleSelection.map((x) => x.userName).join(',')}!`,
            });
            this.selectAll = false;
            this.multipleSelection = [];
            this.$refs.table.clearSelection();
            this.getTeacherPageJSON(1);
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    // handle 批量重置密码
    handleBatchReset() {
      if (this.notAllowOperate) return;
      this.passwordDialogData.optionType = this.selectAll ? consts.PASSWORD_RESET_ALL : consts.PASSWORD_RESET_BATCH;
      this.passwordDialogData.teachers = this.multipleSelection;
      this.passwordDialogData.visible = true;
    },
    // handle 删除老师
    handleDelect(val) {
      if (this.notAllowOperate) return;

      this.$msgbox
        .confirm(`你确定禁用${val.userName}吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          deleteTeacher({
            teacherId: val.userId,
          }).then(() => {
            this.$message({
              type: 'success',
              message: `已删除${val.userName}!`,
            });
            this.getTeacherPageJSON();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    getTeacherPageJSON(pageIndex) {
      getTeacherList({
        gradeId: this.table.gradeId,
        userName: this.table.userName,
        pageSize: this.table.pageSize,
        pageIndex: pageIndex || this.table.pageIndex,
      }).then(({ result }) => {
        if (result.data.length === 0 && this.table.pageIndex !== 1) {
          this.table.pageIndex--;
          this.getTeacherPageJSON();
          return;
        }
        this.table.list = result.data;
        this.table.total = result.recordCount;
      });
    },
    initPage() {
      getTeacherListNew({
        pageSize: this.table.pageSize,
        pageIndex: this.table.pageIndex,
        gradeId: this.table.gradeId,
      }).then(({ result }) => {
        this.grades = [this.grades[0], ...result.gradeList, this.grades[1]];

        this.table.list = result.teacherPage.data;
        this.table.total = result.teacherPage.recordCount;
        this.table.pageIndex = result.pageIndex;
      });
    },

    // 批量录入
    handleImport() {
      if (this.notAllowOperate) return;

      this.$batchEntry({
        type: 'teacher',
        callback: () => {
          this.handleTag({
            gradeId: '',
            gradeName: '全部',
          });
        },
      });
    },

    // 批量导出
    handleExport() {
      this.$batchExport({
        type: 'teacher',
      });
    },
  },
  created() {
    this.initPage();
  },
};

<template>
  <el-dialog
    custom-class="ql-dialog "
    :title="typeWord + '教师'"
    :visible.sync="dialogVisible"
    width="680px"
    @close="handleCancel"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="教师姓名" prop="userName" size="small">
        <el-input v-model="form.userName" :style="{ width: '480px' }" placeholder="请输入教师姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobileNo" size="small">
        <el-input v-model="form.mobileNo" :style="{ width: '480px' }" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="教职工号" prop="teacherNo" size="small">
        <el-input v-model="form.teacherNo" :style="{ width: '480px' }" placeholder="请输入教职工号"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex" size="small">
        <el-radio-group v-model="form.sex">
          <el-radio :label="'男'" @click.native.prevent="handleRadio('男')">男</el-radio>
          <el-radio :label="'女'" @click.native.prevent="handleRadio('女')">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="optionType === DIALOG_TYPE_ADD" label="密码" prop="password" size="small">
        <el-input v-model="form.password" :style="{ width: '100%' }" placeholder="请输入密码"></el-input>
        <div class="tips">
          <p class="tip-content">8-24位，至少包含数字、字母或特殊字符中两种方式</p>
          <p class="tip-content">
            若不填写密码，当教师有职工号时，默认为qljy+职工号后4位，不足4位则以0补位，例：qljy0001；当教师无教职工号时，默认为qljy0000
          </p>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleSave" :disabled="saveClick" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Form, FormItem, Dialog, Button, Input, Radio, RadioGroup } from 'element-ui';
import { getTeacherInfo, saveTeacher } from '@/api/basic';
import { DIALOG_TYPE_ADD, DIALOG_TYPE_EDIT } from '../constance';
import { checkQuote, checkPassword } from '@/utils/validate';

export default {
  name: 'TeacherDialog',
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    optionType: {
      type: Number,
      required: true,
      default: DIALOG_TYPE_ADD,
    },
    gradeId: {
      type: String,
      required: true,
    },
    teacherId: {
      type: String,
      required: false,
    },
  },
  data() {
    const validateUserName = (rule, value, callback) => {
      if (value.trim().length === 0) {
        callback(new Error('姓名不能为空'));
        return;
      }
      if (value.trim().length > 15) {
        callback(new Error('姓名过长'));
        return;
      }
      if (!checkQuote(value)) {
        callback(new Error('姓名不能包含特殊字符'));
        return;
      }
      callback();
    };
    const validateMobileNo = (rule, value, callback) => {
      if (value === '') {
        callback();
        return;
      }
      if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号码不正确'));
        return;
      }
      callback();
    };
    const validateTeacherNo = (rule, value, callback) => {
      if (value === '') {
        callback();
        return;
      }
      if (value.trim().length > 25) {
        callback(new Error('工号过长'));
        return;
      }
      callback();
    };

    return {
      DIALOG_TYPE_ADD,
      DIALOG_TYPE_EDIT,
      dialogVisible: false,
      form: {
        userName: '',
        mobileNo: '',
        teacherNo: '',
        sex: '',
        password: '',
      },
      rules: {
        userName: [
          { required: true, message: '教师姓名不能为空', trigger: 'blur' },
          { validator: validateUserName, trigger: 'blur' },
        ],
        mobileNo: [{ validator: validateMobileNo, trigger: 'blur' }],
        teacherNo: [{ validator: validateTeacherNo, trigger: 'blur' }],
      },
      saveClick: false, // 是否点击了确定
    };
  },
  computed: {
    typeWord() {
      switch (this.optionType) {
        case DIALOG_TYPE_ADD:
          return '新增';
        case DIALOG_TYPE_EDIT:
          return '编辑';
        default:
          return '';
      }
    },
  },
  methods: {
    handleRadio(val) {
      if (this.form.sex === val) {
        this.form.sex = '';
      } else {
        this.form.sex = val;
      }
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.saveClick) return false;

          let params = {
            userName: this.form.userName,
            mobileNo: this.form.mobileNo,
            sex: this.form.sex,
            teacherNo: this.form.teacherNo,
            password: this.form.password,
          };

          if (this.optionType === DIALOG_TYPE_EDIT) {
            params.userId = this.teacherId;
          }

          if (this.form.password) {
            const res = checkPassword(this.form.password);
            if (res !== true) {
              this.$message.error(res);
              return;
            }
          }

          this.saveClick = true;
          saveTeacher(params)
            .then(() => {
              this.$message.success(`${this.typeWord}教师成功`);
              this.$emit('success');
            })
            .finally(() => {
              this.saveClick = false;
            });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.$emit('close');
    },
  },
  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          // reset
          Object.assign(this.$data, this.$options.data());

          this.$nextTick(() => {
            this.$refs.form.clearValidate();
          });

          // 编辑
          if (this.optionType === DIALOG_TYPE_EDIT && this.teacherId.length) {
            getTeacherInfo({
              teacherId: this.teacherId,
            }).then(({ result }) => {
              this.form = Object.assign(this.form, result.teacher);
            });
          }
        }
        this.dialogVisible = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  margin-top: 8px;
  padding: 8px 12px;
  font-size: 12px;
  color: #808080;
  line-height: 22px;
  background-color: #f8f8f8;
  border-radius: 4px;
}
</style>
